// import {
//   BlogOneImage,
//   BlogTwoImage,
//   BlogThreeImage,
//   BlogFourImage,
//   AboutUsImage,
//   featureImage,
// } from "../assets";

import  featureImage  from "../assets/images/Online test-amico.png";
import  study from "../assets/images/Study abroad-amico.png";
import selectImg  from "../assets/images/Select-amico.png";
import education from "../assets/images/forming team leadership-amico.png";
import ai from "../assets/images/Artificial intelligence-amico.png";
import mentor from "../assets/images/leadership-amico.png";

const leftBlogDataObjectList = [
  {
    image: featureImage,
    tag: "NEWS",
    heading: "10 most popular government exams in India and their preparation",
    content:
      "Government jobs in India are the most prestigious jobs and many candidates dream of qualifying for it. As a government employee, one can expect better job stability and security, better career opportunities, professional growth, and financial stability. Not only this, one gets the perks and benefits along with the opportunity to contribute to the nation’s growth. Through this blog, will talk about some prominent government exams in India. Whether you are a recent graduate or someone who is looking to switch your career to the government sector, these popular exams can help you for professional growth and serve the nation.",
     fullContent:[
      {"pr": "Government jobs in India are the most prestigious jobs and many candidates dream of qualifying for it. As a government employee, one can expect better job stability and security, better career opportunities, professional growth, and financial stability. Not only this, one gets the perks and benefits along with the opportunity to contribute to the nation’s growth. Through this blog, will talk about some prominent government exams in India. Whether you are a recent graduate or someone who is looking to switch your career to the government sector, these popular exams can help you for professional growth and serve the nation."},
      {
        "pr": "The craze and competition of cracking the government jobs in India has been constantly increasing every year. Every year lakhs of candidates fill up the application and give their best to qualify the exams. The landscape of government jobs is vast and varied, having vacancies in central and state departments, public sector undertakings [PSUs], and other autonomous bodies. Candidates with educational qualifications ranging from high school to masters’ degree are eligible to work in various government sectors based on the profile."
      },
      {
        "pr": "Although the process of getting recruited is a rigorous process which goes through several stages. It involves conducting the exam tailored to the job profiles requirements, consisting of at least two stages: Tier I and Tier II, followed by interview sessions, document verification and medical examination [if needed]. All this process for the vacancies which are limited but have a maximum number of candidates. So the candidate has to work hard and prepare strategically in order to qualify for their dream job."
      },
      {
        "pr": "We’ll have a comprehensive understanding of some government exams which are popular among candidates, including engineering grads."
      }
    ]
    
  },
];

const blogDataObjectList = [
  {
    image: study,
    tag: "NEWS",
    heading: "A Step-by-Step Guide to Studying Abroad",
    content:
      "Studying abroad can feel overwhelming, especially if you're used to the support of family and friends. However, the experience can significantly transform your career. With countless students opting to study abroad every year, there’s a proven method to make this process feasible and highly attractive. By following a step-by-step approach, you can decode the path to getting admission abroad.",
     fullContent: [
      {"pr" : "Studying abroad can feel overwhelming, especially if you're used to the support of family and friends. However, the experience can significantly transform your career. With countless students opting to study abroad every year, there’s a proven method to make this process feasible and highly attractive. By following a step-by-step approach, you can decode the path to getting admission abroad."},
      {
        "pr": "Study abroad consultants are crucial in ensuring that students navigate this process smoothly and fulfill their aspirations. Here are the key steps to align your efforts for successful admissions abroad:"
      },
      {
        "pr": "Once you consider studying abroad, start researching. Explore websites, watch informative videos, and talk to friends who are already studying overseas. This will familiarize you with the process and options available."
      },
      {
        "pr": "Many students who try to study abroad on their own often encounter mistakes or unnecessary expenses. This is aside from the stress of unexpected issues. Signing up with an overseas education consultant can alleviate these problems. These consultants will help turn your dream into reality by reducing your workload and saving you money."
      },
      {
        "pr": "An overseas education consultant will help you shortlist suitable universities and guide you through the application process. They will advise you on necessary exams and tests. Each university has different application procedures, but with expert guidance, the process will be smooth."
      },
      {
        "pr": "After submitting your applications, you'll start receiving offers. Once you get a satisfactory offer, confirm your acceptance and secure your spot. Having an education consultant by your side can help you address any doubts during this phase."
      },
      {
        "pr": "Once you’ve confirmed your acceptance, the next step is obtaining a student visa. While this can seem daunting, it’s manageable with an offer from a foreign university. Follow the required procedures, and your visa process should proceed smoothly."
      },
      {
        "pr": "With admission and visa preparations underway, the next step is to arrange accommodation. Depending on the country and university, options vary. Some universities offer on-campus housing, while in other places, staying in a paying guest accommodation might be preferable."
      },
      {
        "pr": "Finally, it’s time to bid a temporary farewell to your family and fly to your new campus. Ensure you’re well-prepared. Seek advice from friends and your education consultants about important aspects to consider in your destination country."
      },
      {
        "pr": "Studying abroad is a transformational experience and a significant milestone in your career. Following a step-by-step process can help you achieve this dream without major issues. Engaging with a reputable overseas education consulting firm, like Proventus Education, ensures that you secure the best possible college within your budget on your first attempt."
      }
    ]
    
  },
  {
    image: selectImg,
    tag: "NEWS",
    heading: "Choosing the Right MBA Program: A Guide for International Students",
    content:
      "If you are an international student considering pursuing an MBA program abroad, you are on the right track to a promising career. However, selecting the right MBA program that matches your interests, career goals, and budget can be daunting. Here are some helpful tips to guide you through the process.",
      fullContent: [
        {"pr": "If you are an international student considering pursuing an MBA program abroad, you are on the right track to a promising career. However, selecting the right MBA program that matches your interests, career goals, and budget can be daunting. Here are some helpful tips to guide you through the process."},
        {"pr": "First and foremost, ensure the MBA program you are considering is accredited by reputable organizations such as the Association to Advance Collegiate Schools of Business (AACSB), the Association of MBAs (AMBA), or the European Foundation for Management Development (EFMD). Accreditation status indicates that the program meets rigorous academic standards and is recognized globally by employers, ensuring a high-quality education and a valuable degree"},
        {"pr" : "Program rankings can provide a valuable indication of the quality and reputation of an MBA program. Check out rankings from publications like The Financial Times, The Economist, or Forbes. However, keep in mind that rankings can be subjective and may not always reflect your personal preferences and priorities."},
        {"pr": "MBA programs can be expensive, and the cost of living in different countries can vary significantly. Determine your budget and research the tuition fees, living expenses, and potential scholarships and financial aid options. Remember to factor in the cost of flights, visas, textbooks, and accommodation."},
        {"pr": "An MBA program can offer a variety of specializations such as finance, marketing, or entrepreneurship. Evaluate the curriculum and the specializations offered to ensure they match your career goals and interests. Look for programs that offer experiential learning opportunities, such as internships, case studies, or consulting projects, as they can provide valuable real-world experience."},
        {"pr": "The quality of the faculty can significantly impact the education you receive. Research the faculty members' backgrounds, qualifications, and teaching experience. Look for professors with practical industry experience or who have published research in leading academic journals."},
        {"pr": "Networking is a crucial component of an MBA program. Research the program's alumni network and the professional connections you can potentially make during your studies. Consider attending open houses, speaking with current students or alumni, or attending recruitment fairs to get a better sense of the program and its community."},
        {"pr": "Choosing the right MBA program abroad can be a life-changing decision that shapes your career and provides valuable lifelong skills. Keep in mind that every program has its unique strengths and weaknesses, and what may be the best fit for someone else may not necessarily be the best for you. Take the time to evaluate your options, research your preferences, and make an informed decision that aligns with your personal and professional goals."}
      ]
  },
  {
    image: education,
    tag: "NEWS",
    heading: "Educational Counselling: Major Benefits and Its Importance",
    content:
      "Education plays a pivotal role in shaping individuals' lives and futures. However, navigating the educational journey often comes with challenges, uncertainties, and a need for guidance. This is where educational counseling steps in, providing invaluable support to students at various stages of their academic journey. In this blog, we will explore what educational counseling is, its benefits, and its importance in empowering students to reach their full potential.",
    fullContent:[{
      "pr" : "Education plays a pivotal role in shaping individuals' lives and futures. However, navigating the educational journey often comes with challenges, uncertainties, and a need for guidance. This is where educational counseling steps in, providing invaluable support to students at various stages of their academic journey. In this blog, we will explore what educational counseling is, its benefits, and its importance in empowering students to reach their full potential."
    },
    {"pr": "Educational counseling is a specialized and transformative process that provides tailored guidance to students, addressing their academic, career, and personal development needs. Expert educational counselors collaborate with students to effectively navigate academic challenges, make informed choices, and explore their full potential. By leveraging their skills, knowledge, and resources, educational counselors empower students to overcome obstacles, enhance academic performance, and set a solid foundation for future success."},
    {"pr": " Educational counselors offer individualized assistance, identifying students' unique strengths, weaknesses, and learning styles. They provide targeted strategies to improve study habits, time management skills, and exam preparation techniques, maximizing academic performance."},
    {"pr": "  Educational counselors help students explore various career paths, assess their interests, and make informed decisions. By providing resources such as career assessments, internships, and mentorship programs, they empower students to align their aspirations with market demands and pursue fulfilling careers."},
    {"pr": " Educational counseling recognizes the importance of nurturing students' emotional well-being and personal growth. Counselors address stress management, self-esteem, and interpersonal skills, fostering resilience and overall well-being."}
  ]
    },
  {
    image: ai,
    tag: "NEWS",
    heading: "How To Use AI To Prepare For a Job Interview",
    content:
      "Artificial intelligence (AI) is a powerful ally when it's time to prepare for an interview! In the past, you’d spend hours researching a company, finding and practicing common interview questions, and meticulously tailoring your resume. The process of finding a job could be a time vampire, draining precious minutes and hours from your day. On top of that, it was often simply overwhelming.",
      fullContent: [{
        "pr" : "Artificial intelligence (AI) is a powerful ally when it's time to prepare for an interview! In the past, you’d spend hours researching a company, finding and practicing common interview questions, and meticulously tailoring your resume. The process of finding a job could be a time vampire, draining precious minutes and hours from your day. On top of that, it was often simply overwhelming."
      },
    {"pr": "Enter AI. Now, you have a partner that can help streamline the process. AI can assist with researching a company, advising on your resume (though it’s not recommended to use AI to write your resume for you), and preparing for a job interview. This article will show you how to use AI to prepare for a job interview more efficiently and effectively."}
    ]
  },
  {
    image: mentor,
    tag: "NEWS",
    heading: "The Role of Mentorship in Career Transitions",
    content:
      "“A mentor is someone who sees more talent and ability within you than you see in yourself, and helps bring it out of you. -Bob Proctor, Author & Lecturer”  ",
      fullContent: [
        {"pr": "“A mentor is someone who sees more talent and ability within you than you see in yourself, and helps bring it out of you. -Bob Proctor, Author & Lecturer”  "},
        {"pr": "Navigating the field of career transitions can be challenging. Whether you're considering a career change, starting a new professional journey, or seeking guidance for future planning, individuals often grapple with numerous questions and uncertainties. In such moments, mentorship emerges as an invaluable resource, providing insights, guidance, and support from those who have already walked the path. This blog explores the transformative role of mentorship in facilitating successful career transitions, highlighting how mentorship intersects with career change and guidance to illuminate the path forward."},
        {"pr": "At the heart of every career change lies the desire for growth, fulfillment, and alignment with one’s passions and aspirations. Whether driven by a quest for greater purpose, dissatisfaction with the status quo, or a desire to explore new horizons, the decision to seek career guidance marks a significant moment of transformation. Yet, amidst the excitement and anticipation, uncertainty often looms, leaving individuals questioning the feasibility, direction, and best course of action. In such moments, the guidance and support of a mentor can provide much-needed clarity, perspective, and encouragement, helping individuals navigate the complexities of career transitions with confidence and conviction."},
        {"pr": "At the heart of every career change lies the desire for growth, fulfillment, and alignment with one’s passions and aspirations. Whether driven by a quest for greater purpose, dissatisfaction with the status quo, or a desire to explore new horizons, the decision to seek career guidance marks a significant moment of transformation. Yet, amidst the excitement and anticipation, uncertainty often looms, leaving individuals questioning the feasibility, direction, and best course of action. In such moments, the guidance and support of a mentor can provide much-needed clarity, perspective, and encouragement, helping individuals navigate the complexities of career transitions with confidence and conviction."},
        {"pr": "Central to the concept of a career switch is the idea of embarking on a journey of self-discovery and reinvention. Whether entering a new industry, pursuing entrepreneurship, or exploring alternative career paths, individuals often find themselves navigating uncharted territory, facing unfamiliar challenges and opportunities. The wisdom and insights gained from a mentor’s experiences can serve as a source of guidance, offering practical advice, perspective, and encouragement to navigate the journey ahead. By learning from their mentor’s career transitions, individuals can gain valuable insights into overcoming challenges, seizing opportunities, and charting a course toward success."},
        {"pr": "Mentorship also plays a crucial role in bridging the gap between aspiration and action. Mentors provide individuals with the tools, resources, and support needed to turn their career goals into reality. Whether seeking advice on resume writing, interview preparation, networking strategies, or understanding a new industry, mentors offer invaluable expertise and firsthand knowledge. By serving as a sounding board for ideas, a source of encouragement in moments of doubt, and a guide through career transitions, mentors empower individuals to take bold steps toward achieving their professional goals and aspirations."},
        {"pr": "Beyond practical guidance and support, mentorship offers a unique opportunity for personal and professional growth. Through ongoing dialogue, reflection, and collaboration, mentors and mentees engage in a process of mutual learning and development. This exchange of ideas, perspectives, and insights enriches both parties’ understanding of themselves and their respective fields. For individuals navigating a career transition, this shared learning can be particularly transformative, offering new strategies for overcoming challenges, seizing opportunities, and achieving success in their chosen path."},
        {"pr": "Furthermore, mentorship is a powerful source of confidence, resilience, and self-efficacy in the face of uncertainty and adversity. By offering encouragement, support, and constructive feedback, mentors help individuals develop the skills, mindset, and resilience needed to navigate the inevitable challenges and setbacks that accompany any career transition. Whether grappling with fear of failure or uncertainty about the future, mentors provide reassurance and guidance, helping individuals cultivate the confidence and resilience needed to overcome obstacles and persevere in the pursuit of their professional aims."},
        {"pr": "In today’s India, characterized by rapid economic growth, technological advancement, and globalization, the value of career guidance and counseling has never been more pronounced. With a competitive job market, evolving industry trends, and shifting workforce dynamics, individuals across the country seek guidance and support in navigating their career paths with confidence and clarity. The Global Career Counselling (GCC) program, with its focus on practical skills development, mentorship, and personalized support, is uniquely positioned to meet this demand. The program equips participants with the expertise, insights, and resources needed to succeed in today’s rapidly changing landscape. By empowering individuals with the tools to make informed decisions, set realistic goals, and navigate career planning and development complexities, the GCC program is shaping the future of career guidance and counseling in India, helping individuals realize their full potential and achieve success."},
        {"pr": "The role of mentorship in facilitating successful career transitions cannot be overstated. Whether embarking on a new career path, exploring alternative opportunities, or seeking guidance in navigating the job market, mentorship offers invaluable support, guidance, and encouragement at every stage of the professional journey. By drawing upon the wisdom, insights, and experiences of those who have walked the path before, individuals can navigate career switches with confidence, clarity, and conviction, empowering them to realize their full potential and achieve success in their chosen fields."}
      ]
  },
];

export { leftBlogDataObjectList, blogDataObjectList };
