import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./components/home/Home";
import ContactUs from "./screens/contactus/ContactUs";
import About from "./screens/about/About";
import Carrer from "./screens/carrer/Carrer";
import Blog from "./components/home/blog/Blog";
import Counsellors from "./components/home/counsellors/Counsellors"

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path:"/",
    element:<App/>,
    children:[
      {index:true, path:"/", element:<Home/>},
      { path:"/contactus", element:<ContactUs/>},
      { path:"/about", element:<About/>},
      { path:"/career", element:<Carrer/>},
      { path:"/blog", element:<Blog/>},
      {path:"/counsellors",element:<Counsellors/>}
    ]
  }
])
root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);
