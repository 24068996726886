import React, { useState, useEffect } from "react";
import { RatingStar } from "../../../assets";
import "./Counsellors.css";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import CounsellorImage from "../../../assets/images/councilor_image.png";
import AllCouncliorsObjectList from "../../../data_objects/AllCounsellorsObject";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";

const getResponsiveStyle = () => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 600 ? '100%' : '500px',
    height: "auto",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
  };
};

const Counsellors = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phNo, setPhno] = useState("");
  const [date, setDate] = useState("");
  const [isBtn, setIsBtn] = useState(false);
  const [obj, setObj] = useState({});
  const [modalStyle, setModalStyle] = useState(getResponsiveStyle());

  const handleOpen = (data) => {
    setOpen(true);
    console.log(data);
    setObj(data);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const handleResize = () => {
      setModalStyle(getResponsiveStyle());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhoneNumber = (phNo) => {
    const re = /^\d{10}$/;
    return re.test(phNo);
  };

  const validateDate = (date) => {
    const selectedDate = new Date(date);
    const currentDate = new Date();
    return selectedDate > currentDate;
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setPhno(numericValue);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, "");
    setName(alphabeticValue);
  };

  const submitForm = async () => {
    setIsBtn(true);
    let payload = {
      name: name,
      email: email,
      phNumber: phNo,
      counselorName: obj.name,
      counselorDegi: obj.designation,
      slotDate: date
    }

    if (name === "") {
      toast.error("Name is required");
      setIsBtn(false);
    } else if (email === "") {
      toast.error("Email is required");
      setIsBtn(false);
    } else if (!validateEmail(email)) {
      toast.error("Invalid email format");
      setIsBtn(false);
    } else if (phNo === "") {
      toast.error("Phone number is required");
      setIsBtn(false);
    } else if (!validatePhoneNumber(phNo)) {
      toast.error("Invalid phone number format");
      setIsBtn(false);
    } else if (date === "") {
      toast.error("Date is required");
      setIsBtn(false);
    } else if (!validateDate(date)) {
      toast.error("Date must be in the future");
      setIsBtn(false);
    } else {
      setIsBtn(true);
      await axios.post("https://api.risoapp.com/api/book/create", payload).then(data => {
        if (data) {
          console.log(data);
          toast.success("We received your booking request, our support team will reach out to you shortly.")
          setName("");
          setEmail("");
          setPhno("");
          setDate("");
          setIsBtn(false);
          setOpen(false);
        }
      });
    }

    console.log(payload);
  }

  return (
    <div className="counsellors_container">
      <div className="counsellors_details_section section_padding">
        <div className="heading_container">
          <h1>Our Counsellors</h1>
        </div>
        <div className="councellors_details_card_container">
          {AllCouncliorsObjectList?.map((obj, index) => (
            <div className="counsellors_card" key={index}>
              <div className="counsellors_profile_section">
                <div className="counsellors_profile_img_container">
                  <img src={obj?.prof_pic} alt="profile" />
                </div>
                <div className="counsellors_profile_details">
                  <p className="counsllor_name">{obj?.name}</p>
                  <p className="counsellors_rating_review">
                    <span>{obj?.rating}</span>{" "}
                    <img src={RatingStar} alt="star" />{" "}
                    <span>({obj?.reviews})</span>
                  </p>
                  <p className="counsellors_dsignation">{obj.designation}</p>
                </div>
              </div>
              <div className="counsellors_description">
                <p>{obj?.about}</p>
              </div>
              <div className="counsellors_experience">
                <p>Exp : 300+ Hrs</p>
                <button onClick={() => handleOpen(obj)}>Talk Now</button>
              </div>
            </div>
          ))}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ margin: "10px" }}
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="book-slot-now">
                <h3>Book your slot</h3>
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="slot-container">
                <input type="text" placeholder="Enter your name" value={name} onChange={handleNameChange} />
                <input type="text" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="tel" placeholder="Enter your phone number" value={phNo} onChange={handlePhoneNumberChange} pattern="\d*" />
                <div className="input-container">
                  <input
                    type="datetime-local"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className={date ? '' : 'empty'}
                  />
                  {!date && <span className="placeholder">Enter date and time</span>}
                </div>
                {!isBtn ? <button onClick={submitForm}>Book now</button> : <button onClick={submitForm} disabled>Please wait...</button>}
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Counsellors;
