import User from "../assets/images/mentor-ic.png";
import Settings from "../assets/images/social-ic.png";
import Layers from "../assets/images/carrer-ic.png";
import ShoppingBag from "../assets/images/education-ic.png";

const functoinCardObjectList = [
  {
    icon:  ShoppingBag ,
    heading: "Education",
    para: "Receive expert guidance on educational paths and opportunities. Our education counseling services help you make informed decisions about your academic future, including choosing the right courses, schools, and programs to meet your goals.",
  },
  {
    icon: Layers ,
    heading: "Career",
    para: "Navigate your career journey with confidence through our career counseling services. We offer personalized advice on job searching, career transitions, resume writing, and interview preparation to help you achieve your professional aspirations.",
  },
  {
    icon: Settings,
    heading: "Social",
    para: "Enhance your social well-being with our social counseling services. We provide support to improve your interpersonal skills, manage social challenges, and build a strong, supportive network to foster personal growth.",
  },
  {
    icon: User,
    heading: "Mentorship",
    para: "Benefit from the wisdom and experience of our mentors through our mentorship counseling services. We match you with professionals who can provide valuable insights, advice, and support to help you navigate your career and personal development.",
  },


 

];

export { functoinCardObjectList };
