import React, { useState } from "react";
import "./Features.css";
import featureImage from "../../../assets/images/Service 24_7-rafiki (1).png";
import headerCarousalList from "../../../data_objects/HeaderCarousal";
import { Swiper,SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import comming from "../../../assets/images/IMG-20230512-WA0008 (1).jpg";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  height: "80%",
  overflow: "scroll",
  p: 4,
};

const constants = [
  { 
    key:1,
    title:"Find Jobs Matching Your Skills",
    subtitle:`No need to worry about job listings. We'll find the right jobs for you using our advanced search algorithms. Just sit back and relax—we'll handle the rest.`
  },
  { 
    key:2,
    title:"Discover Schools and Colleges That Match Your Interests",
    subtitle:"No need to worry about endless searching. We'll find the perfect schools and colleges for you using our advanced algorithms. Just sit back and relax—we'll take care of the rest."
  },
  { 
    key:3,
    title:"Find the Best Mental Wellness Resources for You. ",
    subtitle:"No need to worry about where to start. We'll connect you with the perfect mental wellness counselor  using our advanced matching algorithms. Just sit back and relax—we'll handle the rest."
  },
]
const Features = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="riso_features section_padding">
      <div className="riso_feature_image">
        <img src={featureImage} alt="feature_image" />
      </div>
      <Swiper
          slidesPerView={1}
          spaceBetween={0}
          modules={[Autoplay]}
          autoplay={{ delay: 6000 }}
          className="feature_swiper"
        >
        {constants?.map((obj,index)=>{
          return(
          <SwiperSlide>
            <div className="riso_feature_content">
              <h2 className="riso_feature_heading_text">
                  {obj?.title}
                </h2>
                <div className="riso_feature_content_text">
                <p>
                  {obj?.subtitle}
                 </p>
                <button onClick={handleOpen}>Discover Here</button>
                </div>
            </div>  
          </SwiperSlide>
          )
        })}
        </Swiper>

        <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            <span className="term">Comming soon...</span>
            <hr/>
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <div className="comming-img-outer"> 
              <div className="comming-img">
              <img src={comming} />
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Features;
