import React from "react";
import "./Brands.css";

import b1 from "../../../assets/images/logo/5paisa-Capital-Limited-Discount-broker-partner-program.webp";
import b2 from "../../../assets/images/logo/ACC-Limited-Company.webp";
import b3 from "../../../assets/images/logo/Aarti-Industries-Ltd.webp";
import b4 from "../../../assets/images/logo/About-Just-Dial-Limited-Company.webp";
import b5 from "../../../assets/images/logo/About-Vakrangee-Ltd-Company-Information.webp";
import b6 from "../../../assets/images/logo/Aditya-Birla-Fashion-and-Retail-Limited.webp";
import b7 from "../../../assets/images/logo/Amara-Raja-Batteries-Ltd-Company-Brands.webp";
import b8 from "../../../assets/images/logo/Apollo-Diagnostics-Franchise.webp";
import b9 from "../../../assets/images/logo/Bajaj-Electricals-Limited.webp";
import b10 from "../../../assets/images/logo/Balkrishna-Industries-Ltd-and-Off-Road-Tyre-Company.webp";
import b11 from "../../../assets/images/logo/Bharti-Airtel-Limited.webp";
import b12 from "../../../assets/images/logo/Blue-Dart-Logo.webp";
import b13 from "../../../assets/images/logo/Camlin-Fine-Sciences-ltd-Multibagger.webp";
import b14 from "../../../assets/images/logo/Cheviot-Company-Ltd-Products-Multibagger.webp";




const brandLog = [
  {"img" : b1},
  {"img": b2},
  {"img": b3},
  {"img": b4},
  {"img": b5},
  {"img": b6},
  {"img": b7},
  {"img": b8},
  {"img": b9},
  {"img": b10},
  {"img": b11},
  {"img": b12},
  {"img": b13},
  {"img": b14}
]

const Brands = () => {
  return (
    <div className="brands_container section_padding">
      <h2 className="brands_hading">Featured Companies</h2>
      <div class="slider">
        <div class="slide-track">
        {brandLog?.map(br => {
          return(
            <div class="slide">
            <img
              src={br?.img}
              height="100"
              width="200"
              alt=""
            />
          </div>
          )
        })}
        
        </div>
      </div>
    </div>
  );
};

export default Brands;
