import React from "react";
import "./About.css";
import AboutImage from "../../assets/images/Digital presentation-amico.png";
import MissionImg from "../../assets/images/Business mission-amico.png";
import VissionImg from "../../assets/images/Bad idea-amico.png"

const About = () => {
  return (
    <div className="about_section_container">
      <div className="aboutus_container section_padding">
        <div className="aboutus_left_container">
          <p>ABOUT US</p>
          <h2>Explore Riso's Comprehensive Services.</h2>
          <h6>
            Riso reflects its vision and mission in its services. Our Services
            are entitled to address multitude of users ranging from students,
            parents, educators, job seekers, job consultants, corporate
            employees for their various relevant needs. Be it Educational
            counseling we mentor the right path, be it Career related counseling
            we are proactive in it to meet the need and be it Wellness
            especially mental-wellness, we resolve issues by our highly
            experienced and experts best in the industry. We are committed to
            our services to deliver you the best every time.
          </h6>
          {/* <h6>
            The Founder of Riso, Mr. Tusar(Brand Consulting), along with the
            co-founders Mr. Sunil(IT Recruiter) and Mr. Saurabh(Senior
            Developer) who are highly experienced in corporate and IT industry
            having 10+ years of experiences who are experts in their
            disciplines. After many research and development they have come up
            with the RISO a one-stop solution for educational, career and mental
            wellness services which is committed to provide the best services to
            individuals.
          </h6> */}
          {/* <button>Sign up for free</button> */}
        </div>
        <div className="aboutus_right_container">
          <img src={AboutImage} alt="about" />
        </div>
      </div>
      <div className="mission_vision_container section_padding">
        <div className="mission_container">
          <div className="mission_text_section">
            <div>
              <h3>MISSION</h3>
              <div className="mis_underline"></div>
            </div>
            <p>
              To empower individuals by providing adequate educational guidance,
              career coaching and mental wellness through counseling and
              remedial sessions. To bestow them highly personalized and
              optimized educational guidance, career coaching and mental
              wellness services which focuses on the needs of every Individual.
            </p>
          </div>
          <div className="mission_img_section">
            <img src={MissionImg} alt="" />
          </div>
        </div>
        <div className="vision_container">
          <div className="vision_img_section">
            <img src={VissionImg} alt="" />
          </div>
          <div className="vision_text_section">
            <div>
              <h3>VISION</h3>
              
            </div>
            <p>
              To be a leading edu-socio community digital platform to create a
              supportive and sustainable inclusive environment that strives to
              provide personal growth and development of individuals with
              necessary skills and resources which enhance their academic and
              professional interests. One stop solution for educational
              guidance, career coaching and mental wellness service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
