import ic1 from "../assets/images/user-male.png";
import ic2 from "../assets/images/user-female.png";


const AllCouncliorsObjectList = [
  {
    prof_pic: ic1,
    name: "Dr. Arvind Kumar",
    designation: "Career Counselor ",
    about:
      "Dr. Arvind Kumar is a seasoned career counselor with over 10 years of experience in helping individuals find their true calling and succeed in their professional lives.",
    rating: 4.98,
    reviews: 699,
   
  },
  {
    prof_pic: ic2,
    name: "Ms. Kavita Nair",
    designation: "Education Counselor",
    rating: 4.98,
    reviews: 699,
    about:
      "Ms. Kavita Nair has been guiding students for over 8 years, helping them choose the right educational paths and achieve academic excellence.",
  },
  {
    prof_pic: ic1,
    name: "Mr. Anil Deshmukh",
    designation: "Social Counselor",
    rating: 4.98,
    reviews: 699,
    about:
      "Mr. Anil Deshmukh is a dedicated social counselor with a focus on improving interpersonal relationships and social skills.",
  },
  {
    prof_pic: ic2,
    name: "Dr. Ritu Malhotra",
    designation: "Mentorship Counselor",
    rating: 4.98,
    reviews: 699,
    about:
      "Dr. Ritu Malhotra is a mentorship counselor who has been providing guidance and support to young professionals for over 9 years.",
  },
  {
    prof_pic: ic1,
    name: "Mr. Rajesh Patel",
    designation: "Career Advisor",
    rating: 4.98,
    reviews: 699,
    about:
      "Mr. Rajesh Patel is a seasoned career advisor with over 12 years of experience in providing career guidance and support. He excels in career coaching, professional development, and networking strategies, empowering clients to pursue fulfilling career paths.",
  },
  {
    prof_pic: ic2,
    name: "Ms. Sanya Singh",
    designation: "Educational Psychologist",
    rating: 4.98,
    reviews: 699,
    about:
      "Ms. Sanya Singh has been helping students understand their learning styles and achieve their educational goals for over 8 years. She specializes in educational assessments, personalized learning plans, and academic counseling, ensuring that each student receives tailored support.",
  },
  {
    prof_pic: ic2,
    name: "Dr. Neha Bansal",
    designation: "Mental Health Counselor",
    rating: 4.98,
    reviews: 699,
    about:
      "Dr. Neha Bansal is a mental health counselor with a focus on managing stress and emotional well-being. With expertise in cognitive-behavioral therapy and mindfulness techniques, she helps clients navigate life's challenges and improve their mental health.",
  },
  {
    prof_pic: ic1,
    name: "Mr. Vivek Sharma",
    designation: "Career Consultant",
    rating: 4.98,
    reviews: 699,
    about:
      "Mr. Vivek Sharma is a career consultant with a wealth of experience in guiding individuals to achieve their professional goals. He provides career assessments, job market insights, and professional branding advice, helping clients to effectively position themselves in the job market.",
  },
  {
    prof_pic: ic2,
    name: "Ms. Rekha Iyer",
    designation: "School Counselor",
    rating: 4.98,
    reviews: 699,
    about:
      "Ms. Rekha Iyer has been guiding school students for over 8 years, helping them navigate their academic and personal growth. She offers counseling on academic performance, college readiness, and personal development, ensuring that students are well-prepared for their futures.",
  },
  {
    prof_pic: ic2,
    name: "Ms. Priya Mehta",
    designation: "Academic Advisor",
    rating: 4.98,
    reviews: 699,
    about:
      "Ms. Priya Mehta is an experienced academic advisor with a focus on helping students achieve their educational objectives. She offers course selection guidance, study skills workshops, and academic performance reviews, supporting students in their academic journeys.",
  },
  {
    prof_pic: ic1,
    name: "Dr. Manish Gupta",
    designation: "Job Placement Specialist",
    rating: 4.98,
    reviews: 699,
    about:
      "Dr. Manish Gupta specializes in job placements, helping individuals secure the right job opportunities and excel in their careers. His services include job matching, interview preparation, and career transition support, ensuring that clients find roles that suit their skills and interests.",
  },
  {
    prof_pic: ic2,
    name: "Ms. Anjali Verma",
    designation: "Educational Counselor",
    rating: 4.98,
    reviews: 699,
    about:
      "Ms. Anjali Verma has been providing educational counseling and support to students for over 7 years. She assists with college selection, application processes, and exam preparation, helping students to navigate their academic paths and achieve success.",
  },
  {
    prof_pic: ic1,
    name: "Mr. Rohit Kapoor",
    designation: "Career Coach",
    rating: 4.98,
    reviews: 699,
    about:
      "Mr. Rohit Kapoor is a career coach with over 10 years of experience in helping professionals navigate their career paths. He offers career planning, leadership development, and skills enhancement programs, guiding clients to reach their full career potential.",
  },
  {
    prof_pic: ic2,
    name: "Dr. Sunita Joshi",
    designation: "Psychotherapist",
    rating: 4.98,
    reviews: 699,
    about:
      "Dr. Sunita Joshi is a dedicated psychotherapist with over 15 years of experience in helping individuals achieve emotional well-being. She provides therapy for anxiety, depression, and trauma, fostering a safe and supportive environment for her clients.",
  },
  {
    prof_pic: ic1,
    name: "Mr. Abhay Agarwal",
    designation: "Vocational Counselor",
    rating: 4.98,
    reviews: 699,
    about:
      "Mr. Abhay Agarwal has been providing vocational counseling for over 10 years, helping individuals choose suitable career paths. He specializes in vocational assessments, skills training, and job readiness programs, supporting clients in their professional development.",
  },
];

export default AllCouncliorsObjectList;
