import React from "react";
import "./Home.jsx";
import Header from "./header/Header.jsx";
import Features from "./features/Features.jsx";
import Functions from "./functions/Functions.jsx";
import OurCouncilor from "./our_councilor/OurCouncilor.jsx";
import Blog from "./blog/Blog.jsx";
import Navbar from "../navbar/Navbar.jsx";
import Footer from "../footer/Footer.jsx";
import Slider from "./slider/Slider.jsx";
import Brands from "./brands/Brands.jsx";
import { useRef } from "react";

const Home = (ref) => {
  return (
    <div>
      <Header />
      <Features />
      <Functions/>
      <OurCouncilor/>
      <Blog  ref={ref}/>
      <Slider />
      <Brands />
    </div>
  );
};

export default Home;
