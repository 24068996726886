export { default as featureImage } from "./images/feature_image.svg";
export { default as headerImage } from "./images/header_image.png";
export { default as User } from "./images/icons/riso_user_icon.svg";
export { default as Settings } from "./images/icons/riso_settings_icon.svg";
export { default as Layers } from "./images/icons/riso_layers_icon.png";
export { default as ShoppingBag } from "./images/icons/riso_shopping_bag.png";
export { default as ArrowRight } from "./images/icons/arrow-right.svg";
export { default as BlogOneImage } from "./images/blog/riso_blog_one.jpg";
export { default as BlogTwoImage } from "./images/blog/riso_blog_two.jpg";
export { default as BlogThreeImage } from "./images/blog/riso_blog_three.jpg";
export { default as BlogFourImage } from "./images/blog/riso_blog_four.jpg";
export { default as AboutUsImage } from "./images/about_us_image.webp";
export { default as ClientImage } from "./images/client_profile_pic.jpg";
export { default as RatingStar } from "./images/rating_star.svg";
export { default as CounsellorImage } from "./images/councilor_image.png";
