import icon1 from "../assets/images/user-male.png";
import icon2 from "../assets/images/user-female.png";




const councliorsObjectList = [
  {
    icon: icon1,
    name: "Dr. Arvind Kumar",
    designation: "Career Counselor ",
    about:
      "Dr. Arvind Kumar is a seasoned career counselor with over 10 years of experience in helping individuals find their true calling and succeed in their professional lives.",
  },
  {
    icon: icon2,
    name: "Ms. Kavita Nair",
    designation: "Education Counselor",
    about:
      "Ms. Kavita Nair has been guiding students for over 8 years, helping them choose the right educational paths and achieve academic excellence.",
  },
  {
    icon: icon1,
    name: "Mr. Anil Deshmukh",
    designation: "Social Counselor",
    about:
      "Mr. Anil Deshmukh is a dedicated social counselor with a focus on improving interpersonal relationships and social skills.",
  },
  {
    icon: icon2,
    name: "Dr. Ritu Malhotra",
    designation: "Mentorship Counselor",
    about:
      "Dr. Ritu Malhotra is a mentorship counselor who has been providing guidance and support to young professionals for over 9 years.",
  },
];

export default councliorsObjectList;
