import React, { useState } from "react";
import "./Blog.css";

import { ArrowRight, BlogTwoImage } from "../../../assets";
import {
  blogDataObjectList,
  leftBlogDataObjectList,
} from "../../../data_objects/blogDataObject";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  height: "70%",
  overflow: "scroll",
  p: 4,
};

const Blog = () => {
  const [open, setOpen] = useState(false);
  const [obj, setObj] = useState({});
  const handleClose = () => setOpen(false);
  
  const handleOpen = (data) => {
    setOpen(true);
    setObj(data)
  }
  return (
    <div className="riso_blog section_padding" >
      <div className="riso_blog_content_heading">
        <h2>News and blogs</h2>
      </div>
      <div className="riso_blog_card_container">
        <div className="riso_blog_card_container_left_card">
          <div className="riso_blog_card_container_left_card_image_container">
            <img src={leftBlogDataObjectList[0]?.image} alt="blog_image" />
          </div>
          <div className="riso_blog_card_container_left_card_content">
            <button className="riso_blog_tag_button">
              {leftBlogDataObjectList[0]?.tag}
            </button>
            <div className="riso_blog_card_container_left_card_content_text">
              <h4>{leftBlogDataObjectList[0]?.heading}</h4>
              <p>{leftBlogDataObjectList[0]?.content}</p>
            </div>
            <button onClick={() => handleOpen(leftBlogDataObjectList[0])} className="riso_blog_tag_readmore_button">
              Read more
              <img src={ArrowRight} />
            </button>
          </div>
        </div>
        <div className="riso_blog_card_container_right_card_container">
          {blogDataObjectList?.map((object, index) => {
            return (
              <div className="riso_blog_card_container_right_card">
                <div className="riso_blog_card_container_right_card_image_container">
                  <img src={object?.image} alt="blog_image" />
                </div>
                <div className="riso_blog_card_container_right_card_content">
                  <button className="riso_blog_tag_button">{object?.tag}</button>
                  <div className="riso_blog_card_container_right_card_content_text">
                    <div>
                      <h4>{object?.heading}</h4>
                      <p>{object?.content}</p>
                    </div>
                    <button onClick={() => handleOpen(object)} className="riso_blog_tag_readmore_button">
                      Read more
                      <img src={ArrowRight} />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{margin: "10px"}}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="book-slot-now">
                <h3>{obj?.heading}</h3>
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="blg-sec-pop">
                 {/* <h3>{obj?.heading}</h3> */}
                 {obj?.fullContent?.map(ful => {
                  return(
                    <p>{ful?.pr}</p>
                  )
                 })}
              </div>
            </Typography>
          </Box>
        </Modal>
    </div>
  );
};

export default Blog;
