import img1 from "../assets/images/1.png";
import img2 from "../assets/images/2.png";
import img3 from "../assets/images/3.png";


const headerCarousalList = [
  {
    heading: "Find your desired job in seconds",
    sub_heading:
      "The best way to predict the future is to create it, just find you job by skills and experience.",
      image: img2
  },
  {
    heading: "Find the best school or collage of your choice.",
    sub_heading: "Education is the tool that breaks down all barriers. ",
    image: img1
  },
  {
    heading: "Find your mate who will listen you without judging",
    sub_heading:
      "You don’t have to control your thoughts. You just have to stop letting them control you",
      image: img3
  },
];

export default headerCarousalList;
