import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./Slider.css";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import reviewerObjectList from "../../../data_objects/sliderDataObjects";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function Slider() {
  return (
    <div className="slider_container section_padding">
      <h2>What our customers think</h2>
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 3000 }}
        className="mySwiper"
      >
        {reviewerObjectList?.map((reviewer, index) => {
          return (
            <SwiperSlide>
              <div className="swiper_client_msg">
                <p>{reviewer?.review}</p>
              </div>
              <div className="swiper_client_img">
                <figure>
                  <img src={reviewer?.image} alt="" />
                </figure>
                <div className="client_data_details">
                  <p className="client_name">{reviewer?.name}</p>
                  <p className="client_type">{reviewer?.designation}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="phone_view_slider_container">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 3000 }}
          className="phone_view_slider"
        >
          {reviewerObjectList?.map((reviewer, index) => {
            return (
              <SwiperSlide>
                <div className="swiper_client_msg">
                  <p>{reviewer?.review}</p>
                </div>
                <div className="swiper_client_img">
                  <figure>
                    <img src={reviewer?.image} alt="" />
                  </figure>
                  <div className="client_data_details">
                    <p className="client_name">{reviewer?.name}</p>
                    <p className="client_type">{reviewer?.designation}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
