import React, { useState, useRef } from "react";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./ContactUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imgMe from "../../assets/images/Mention-amico.png"

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [phno, setPhno] = useState("");
  const [isBtn, setIsBtn] = useState(false);

  const formRef = useRef(null);
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhoneNumber = (phno) => {
    const re = /^\d{10}$/;
    return re.test(phno);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setPhno(numericValue);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, "");
    setName(alphabeticValue);
  };

  const mailPost = async (data) => {
    setIsBtn(true);
    let payload = {
      name,
      email,
      text,
      phno
    };

    if (name === "") {
      toast.error("Name is required");
      setIsBtn(false);
    } else if (email === "") {
      toast.error("Email is required");
      setIsBtn(false);
    }else if (!validateEmail(email)) {
      toast.error("Invalid email format");
      setIsBtn(false);
    }else if (phno === "") {
      toast.error("Phone number is required");
      setIsBtn(false);
    }else if (!validatePhoneNumber(phno)) {
      toast.error("Invalid phone number format");
      setIsBtn(false);
    }
    else if (text === "") {
      toast.error("Message is required");
      setIsBtn(false);
    } else {
      setIsBtn(true);
      await axios.post("https://api.risoapp.com/api/mail/create", payload).then(data => {
        if (data) {
          console.log(data);
          toast.success("Successfully sent to our support team, we will get back to you soon.");
          setName("");
          setEmail("");
          setPhno("");
          setText("");
          setIsBtn(false);
        }
      });
    }
  };

  const handleGenerateClick = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="contactus_container">
      <div className="contact-inner">
        <h2>GET IN TOUCH.</h2>
        <p>Want to get in touch? We'd love to hear from you. Here how you can reach us.</p>
        <div className="inner-cards">
          <div className="inner-card">
            <i className="fa-solid fa-phone"></i>
            <h2>Talk to our executive</h2>
            <p>+91 7676187272</p>
            <h6>support@risoapp.com</h6>
          </div>
          <div className="inner-card">
            <i className="fa-solid fa-support"></i>
            <h2>Generate a ticket</h2>
            <p>If you have any queries, click the generate button to submit a ticket.</p>
            <button onClick={handleGenerateClick}>Generate</button>
          </div>
          <div className="inner-card">
            <i className="fa-solid fa-location"></i>
            <h2>Location</h2>
            <p>Neeladri Investment Layout Doddathogur Electronic City
              Bengaluru, 560100</p>
          </div>
        </div>
      </div>

      <div className="contact-outer" ref={formRef}>
        <div className="img-co-cont">
          <img src={imgMe} alt="Mention" />
        </div>
        <div className="co-contr">
          <div className="co-contr-inner">
            <h2>Submit your query</h2>
            <p>All fields are required *</p>
          </div>
          <div className="outer-form" >
            <input type="text" value={name} onChange={handleNameChange} placeholder="Your Name" />
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your Email" />
            <input type="text" value={phno} onChange={handlePhoneNumberChange} placeholder="Phone Number" />
            <textarea value={text} onChange={(e) => setText(e.target.value)} placeholder="Your Message"></textarea>
            {!isBtn ? <button onClick={mailPost}>Send</button> : <button onClick={mailPost} disabled>Please wait...</button>}
            <div className="social-outer-new">
              <a href="https://www.facebook.com/share/3Dg4aY7rSEvxmns7/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f"></i></a>
              <a href="https://www.linkedin.com/company/risoapp/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
              <a href="https://x.com/app_riso" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-x-twitter"></i></a>
              <a href="https://www.instagram.com/contact.riso?utm_source=qr&igsh=MXV1amhyeXR0d216cg==" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;
