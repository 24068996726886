import React from "react";
import "./Header.css";
import "swiper/css";
import "swiper/css/pagination";
import headerImage from "../../../assets/images/header_image.png";
import { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import headerCarousalList from "../../../data_objects/HeaderCarousal";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import comming from "../../../assets/images/IMG-20230512-WA0008 (1).jpg"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  height: "80%",
  overflow: "scroll",
  p: 4,
};
const Header = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="riso_header section_padding">
      <div className="riso_header_container">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          modules={[Autoplay]}
          autoplay={{ delay: 6000 }}
          className="header_swiper"
        >
          {headerCarousalList?.map((obj, index) => {
            return (
              <SwiperSlide>
                <div className="riso_header_content">
                  <h1 className="header_heading_text">{obj.heading}</h1>
                  <div className="riso_header_content_text">
                    <p>{obj?.sub_heading}</p>
                    <button onClick={handleOpen}>Search Now</button>
                  </div>
                </div>
                <div className="riso_header_content_image">
                  <img src={obj?.image} alt="header_image" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* <svg
        className="triangular_cutout_for_header"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon fill="white" points="0,100 100,0 100,100" />
      </svg> */}

<Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            <span className="term">Comming soon...</span>
            <hr/>
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <div className="comming-img-outer"> 
              <div className="comming-img">
              <img src={comming} />
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Header;
