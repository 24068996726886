import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/images/riso_logo.png";

const Menu = () => {
  return (
    <>
      <p>
        <NavLink exact to="/" className={({ isActive }) => isActive ? "active-link" : ""}>Home</NavLink>
      </p>
      <p>
        <NavLink to="/about" className={({ isActive }) => isActive ? "active-link" : ""}>About</NavLink>
      </p>
      <p>
        <NavLink to="/career" className={({ isActive }) => isActive ? "active-link" : ""}>Career</NavLink>
      </p>
      <p>
        <NavLink to="/blog" className={({ isActive }) => isActive ? "active-link" : ""}>Blog</NavLink>
      </p>
      <p>
        <NavLink to="/contactus" className={({ isActive }) => isActive ? "active-link" : ""}>Contact us</NavLink>
      </p>
    </>
  );
};

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="riso_navbar">
      <div className="riso_navbar_links">
        <div className="riso_navbar_links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="riso_navbar_links_container">
          <Menu />
        </div>
      </div>
      <div className="riso_toggle_menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#343434"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#343434"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="riso_navbar_menu_container scale-up-center ">
            <div className="riso_navbar_menu_container_links">
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
