import React, { useState } from 'react';
import "./Carrer.css";
import imgC from "../../assets/images/1_9BrpVqQkpXGPP4fLcrk5Dw.gif";
import mutedVideo from '../../assets/video/4723-179738625.mp4';
import innov from "../../assets/images/Innovation-pana.png";
import integrity from "../../assets/images/Software integration-pana.png";
import team from "../../assets/images/Design team-amico.png";
import exce from "../../assets/images/Team-amico.png"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { IoIosArrowForward } from "react-icons/io";



const getResponsiveStyle1 = () => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 600 ? '100%' : '70%',
    height: "70%",
    overflow: "scroll",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline: "none",
    p: 4,
  };
};


const getResponsiveStyle2 = () => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 600 ? '100%' : '500px',
    height: "auto",
    overflow: "scroll",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline: "none",
    p: 4,
  };
};




const Jd = [
  {
    "_id": "riso-5",
    "name": "MERN Stack Developer",
    "about": "We are looking for a passionate MERN Stack Developer to join our team and contribute to building robust and scalable web applications. The ideal candidate should have a strong grasp of JavaScript and its frameworks, along with experience in creating full-stack applications using MongoDB, Express.js, React.js, and Node.js.",
    "Experience": "2-5 years",
    "Job Location": ["Bangalore", "Hyderabad"],
    "Education": "Any Graduate or Post Graduate",
    "Role": "MERN Stack Developer",
    "Qualifications": [
      { "desc": "Proven experience as a MERN Stack Developer or similar role" },
      { "desc": "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model" },
      { "desc": "Experience with front-end technologies like HTML, CSS, and JavaScript frameworks, particularly React.js" },
      { "desc": "Experience with back-end technologies like Node.js and Express.js" },
      { "desc": "Familiarity with MongoDB and other NoSQL databases" },
      { "desc": "Understanding of REST APIs, GraphQL, and microservices architecture" },
      { "desc": "Knowledge of modern front-end build pipelines and tools, such as Webpack, Babel, NPM, etc." },
      { "desc": "Experience with version control systems, preferably Git" },
      { "desc": "Excellent problem-solving skills and a knack for writing clean, maintainable code" },
      { "desc": "BSc in Computer Science, Engineering or relevant field" }
    ],
    "Responsibilities": [
      { "id": "Develop and maintain high-quality, responsive web applications using the MERN stack" },
      { "id": "Collaborate with cross-functional teams to define, design, and ship new features" },
      { "id": "Write clean, maintainable, and efficient code" },
      { "id": "Ensure the performance, quality, and responsiveness of applications" },
      { "id": "Identify and address performance bottlenecks and bugs" },
      { "id": "Work with front-end developers to integrate user-facing elements with server-side logic" },
      { "id": "Participate in code reviews and provide constructive feedback" },
      { "id": "Stay up-to-date with emerging technologies and industry trends" }
    ],
    "Requirements": [
      { "da": "MongoDB" },
      { "da": "Express.js" },
      { "da": "React.js" },
      { "da": "Node.js" },
      { "da": "RESTful APIs" },
      { "da": "GraphQL" },
      { "da": "Webpack" },
      { "da": "Version control systems like Git" }
    ]
  },
  {
    "_id": "riso-6",
    "name": "MEAN Stack Developer",
    "about": "We are seeking a skilled MEAN Stack Developer to build and maintain our web applications using MongoDB, Express.js, Angular, and Node.js. The ideal candidate will have experience in developing full-stack applications and a strong understanding of web technologies.",
    "Experience": "3-6 years",
    "Job Location": ["Delhi", "Mumbai"],
    "Education": "Any Graduate or Post Graduate",
    "Role": "MEAN Stack Developer",
    "Qualifications": [
      { "desc": "Proven experience as a MEAN Stack Developer or similar role" },
      { "desc": "Strong knowledge of MongoDB and its query language" },
      { "desc": "Proficiency in Node.js and Express.js for server-side development" },
      { "desc": "Experience with front-end development using Angular" },
      { "desc": "Understanding of RESTful APIs and web services" },
      { "desc": "Experience with version control systems, preferably Git" },
      { "desc": "Familiarity with front-end build tools and package managers like npm, Webpack, and Gulp" },
      { "desc": "Knowledge of front-end technologies such as HTML, CSS, and JavaScript" },
      { "desc": "Strong problem-solving skills and attention to detail" },
      { "desc": "BSc in Computer Science, Engineering or related field" }
    ],
    "Responsibilities": [
      { "id": "Develop and maintain scalable web applications using the MEAN stack" },
      { "id": "Design and implement APIs to interact with the front-end and database" },
      { "id": "Build efficient and reusable front-end components using Angular" },
      { "id": "Write server-side code using Node.js and Express.js" },
      { "id": "Ensure the performance, quality, and responsiveness of applications" },
      { "id": "Collaborate with designers and back-end developers to deliver high-quality features" },
      { "id": "Conduct code reviews and mentor junior developers" },
      { "id": "Stay up-to-date with emerging technologies and industry trends" }
    ],
    "Requirements": [
      { "da": "MongoDB" },
      { "da": "Express.js" },
      { "da": "Angular" },
      { "da": "Node.js" },
      { "da": "RESTful APIs" },
      { "da": "Git" },
      { "da": "Front-end build tools like npm, Webpack" }
    ]
  },
  {
    "_id": "riso-7",
    "name": "Java Test Engineer",
    "about": "We are looking for a skilled Java Test Engineer to join our quality assurance team. The ideal candidate should have experience in designing, implementing, and executing automated tests for Java applications. You will be responsible for ensuring the quality and performance of our software products.",
    "Experience": "3-5 years",
    "Job Location": ["Chennai", "Pune"],
    "Education": "B.E./B.Tech in Computer Science or related field",
    "Role": "Java Test Engineer",
    "Qualifications": [
      { "desc": "Proven experience as a Java Test Engineer or similar role" },
      { "desc": "Strong knowledge of Java programming and testing frameworks such as JUnit, TestNG" },
      { "desc": "Experience with automation tools such as Selenium, JUnit, TestNG, or Cucumber" },
      { "desc": "Familiarity with continuous integration/continuous deployment (CI/CD) practices" },
      { "desc": "Good understanding of software development lifecycle (SDLC) and software testing life cycle (STLC)" },
      { "desc": "Experience with version control systems such as Git" },
      { "desc": "Excellent analytical and problem-solving skills" },
      { "desc": "BSc in Computer Science, Engineering or a related field" }
    ],
    "Responsibilities": [
      { "id": "Design, develop, and execute automated test scripts for Java applications" },
      { "id": "Analyze and report on test results, identifying defects and areas for improvement" },
      { "id": "Collaborate with developers to ensure quality and performance standards are met" },
      { "id": "Perform functional, integration, and regression testing of software products" },
      { "id": "Participate in code reviews to ensure code quality and adherence to best practices" },
      { "id": "Develop and maintain automated test frameworks and test data" },
      { "id": "Conduct performance testing and load testing to ensure system stability" },
      { "id": "Stay up-to-date with the latest testing tools and technologies" }
    ],
    "Requirements": [
      { "da": "Java programming" },
      { "da": "JUnit" },
      { "da": "TestNG" },
      { "da": "Selenium" },
      { "da": "Cucumber" },
      { "da": "CI/CD practices" },
      { "da": "Software development lifecycle (SDLC)" },
      { "da": "Git" }
    ]
  },
  {
    "_id": "riso-8",
    "name": "DevOps Engineer",
    "about": "We are looking for a DevOps Engineer to join our team to enhance the development and operational processes. The ideal candidate will have a strong background in cloud infrastructure, CI/CD pipelines, and automation tools. You will be responsible for managing deployments, improving system performance, and ensuring the stability and scalability of our infrastructure.",
    "Experience": "4-7 years",
    "Job Location": ["Bangalore", "Hyderabad"],
    "Education": "B.E./B.Tech in Computer Science or related field",
    "Role": "DevOps Engineer",
    "Qualifications": [
      { "desc": "Proven experience as a DevOps Engineer or similar role" },
      { "desc": "Strong knowledge of cloud platforms such as AWS, Azure, or Google Cloud" },
      { "desc": "Experience with containerization technologies like Docker, Kubernetes" },
      { "desc": "Proficiency in scripting languages such as Bash, Python, or Perl" },
      { "desc": "Experience with CI/CD tools like Jenkins, GitLab CI, or Travis CI" },
      { "desc": "Understanding of infrastructure as code (IaC) tools like Terraform, CloudFormation" },
      { "desc": "Experience with monitoring and logging tools such as Prometheus, Grafana, ELK stack" },
      { "desc": "Strong problem-solving skills and ability to troubleshoot complex systems" },
      { "desc": "BSc in Computer Science, Engineering or related field" }
    ],
    "Responsibilities": [
      { "id": "Manage and maintain cloud infrastructure and deployment pipelines" },
      { "id": "Automate infrastructure provisioning and configuration management using tools like Terraform, Ansible" },
      { "id": "Develop and maintain CI/CD pipelines for seamless code deployment" },
      { "id": "Monitor system performance and troubleshoot issues to ensure high availability" },
      { "id": "Implement and manage containerization solutions using Docker and Kubernetes" },
      { "id": "Work closely with development teams to streamline development and deployment processes" },
      { "id": "Ensure compliance with security standards and best practices" },
      { "id": "Keep up-to-date with the latest DevOps tools and practices" }
    ],
    "Requirements": [
      { "da": "Cloud platforms like AWS, Azure, Google Cloud" },
      { "da": "Containerization technologies: Docker, Kubernetes" },
      { "da": "Scripting languages: Bash, Python, Perl" },
      { "da": "CI/CD tools: Jenkins, GitLab CI, Travis CI" },
      { "da": "Infrastructure as code (IaC) tools: Terraform, CloudFormation" },
      { "da": "Monitoring and logging tools: Prometheus, Grafana, ELK stack" },
      { "da": "Problem-solving skills and ability to troubleshoot complex systems" }
    ]
  },
  {
    "_id": "riso-9",
    "name": "Database Engineer",
    "about": "We are seeking a skilled Database Engineer to design, develop, and maintain our database systems. The ideal candidate should have a strong understanding of database management and optimization techniques. You will be responsible for ensuring the performance, integrity, and security of our databases.",
    "Experience": "3-6 years",
    "Job Location": ["Mumbai", "Chennai"],
    "Education": "B.E./B.Tech in Computer Science or related field",
    "Role": "Database Engineer",
    "Qualifications": [
      { "desc": "Proven experience as a Database Engineer, Database Administrator, or similar role" },
      { "desc": "Strong knowledge of database architecture, data modeling, and database design principles" },
      { "desc": "Experience with database management systems (DBMS) such as MySQL, PostgreSQL, MongoDB" },
      { "desc": "Proficiency in SQL and database query optimization techniques" },
      { "desc": "Familiarity with NoSQL databases and big data technologies" },
      { "desc": "Ability to troubleshoot and resolve database issues in a timely manner" },
      { "desc": "Experience with database security best practices and data protection regulations" },
      { "desc": "BSc in Computer Science, Engineering or a related field" }
    ],
    "Responsibilities": [
      { "id": "Design, develop, and manage database architecture for various applications and systems" },
      { "id": "Optimize database performance through monitoring, tuning, and query optimization" },
      { "id": "Ensure data integrity and security by implementing access controls and regular backups" },
      { "id": "Collaborate with software developers to integrate databases into applications" },
      { "id": "Troubleshoot database-related issues and provide timely resolution" },
      { "id": "Perform database upgrades and migrations as required" },
      { "id": "Develop and maintain documentation of database processes and procedures" },
      { "id": "Stay up-to-date with database technologies and best practices" }
    ],
    "Requirements": [
      { "da": "Database management systems (DBMS): MySQL, PostgreSQL, MongoDB" },
      { "da": "SQL and database query optimization" },
      { "da": "NoSQL databases and big data technologies" },
      { "da": "Database security and data protection regulations" }
    ]
  },
  {
    "_id": "riso-10",
    "name": "Data Analyst",
    "about": "We are looking for a detail-oriented Data Analyst to join our team. The ideal candidate should have strong analytical skills and a knack for interpreting data. You will be responsible for collecting, analyzing, and interpreting data to help inform business decisions and improve operational processes.",
    "Experience": "2-4 years",
    "Job Location": ["Bangalore", "Pune"],
    "Education": "BSc/BA in Mathematics, Economics, Computer Science, Information Management or Statistics",
    "Role": "Data Analyst",
    "Qualifications": [
      { "desc": "Proven work experience as a Data Analyst or Business Data Analyst" },
      { "desc": "Technical expertise regarding data models, database design development, data mining and segmentation techniques" },
      { "desc": "Strong knowledge of and experience with reporting packages (Business Objects etc), databases (SQL etc), programming (XML, Javascript, or ETL frameworks)" },
      { "desc": "Knowledge of statistics and experience using statistical packages for analyzing datasets (Excel, SPSS, SAS etc)" },
      { "desc": "Strong analytical skills with the ability to collect, organize, analyze, and disseminate significant amounts of information with attention to detail and accuracy" },
      { "desc": "Adept at queries, report writing and presenting findings" }
    ],
    "Responsibilities": [
      { "id": "Interpreting data, analyzing results using statistical techniques" },
      { "id": "Developing and implementing databases, data collection systems, data analytics and other strategies that optimize statistical efficiency and quality" },
      { "id": "Acquiring data from primary or secondary data sources and maintaining databases/data systems" },
      { "id": "Identifying, analyzing, and interpreting trends or patterns in complex data sets" },
      { "id": "Filtering and cleaning data, and reviewing computer reports, printouts, and performance indicators to locate and correct code problems" },
      { "id": "Work with management to prioritize business and information needs" },
      { "id": "Locate and define new process improvement opportunities" }
    ],
    "Requirements": [
      { "da": "Data models and database design development" },
      { "da": "Data mining and segmentation techniques" },
      { "da": "Reporting packages: Business Objects, SQL, etc." },
      { "da": "Programming languages: XML, Javascript, ETL frameworks" },
      { "da": "Statistics and statistical packages: Excel, SPSS, SAS, etc." },
      { "da": "Strong analytical skills and attention to detail" }
    ]
  },

]



const joMainObj = [
  {
    "_id": "riso-1",
    "name": "Lead DevOps Engineer",
    "about": "The DevOps Engineering Lead heads a small team of engineers to research, define, implement, and institutionalize a suite of tools; these tools will help transform the creation, testing, and deployment of software into a low friction, streamlined workflow. The Engineering Lead will also execute and automate operational processes accurately, securely, and in a timely manner in promotion of the overall success of the organization.",
    "Responsibilities": [
      { "id": "Identify the opportunities most applicable for investment in DevOps technologies" },
      { "id": "Define the tools and workflows that can be applied to elevate the current constraints into low-friction processes" },
      { "id": "Lead the team in prototyping, development, and release of the tools" },
      { "id": "Drive adoption and institutionalization of the new tools and practices across the organization" },
      { "id": "Lead the DevOps efforts across teams" },
      { "id": "Identify pain points that can be resolved with automation by working with the development and support teams" },
      { "id": "Design and deploy strategies for application updates and fresh environment deploys" },
      { "id": "Implement automated unit and integration testing (when applicable)" },
      { "id": "Ensure Security and Compliance requirements are met" },
      { "id": "Architect a custom Jenkins pipeline library to simplify the process of adding new solutions to the pipeline" },
      { "id": "Work with all development teams to standardize QA automation" },
      { "id": "Work with CM team and App Support on deployment automation workflow and requirements" },
      { "id": "Comprehensively document each automated process and add to knowledge base for support" },
      { "id": "Work with all development and support teams to identify and provide solutions where CI/CD automation is needed" }
    ],
    "Qualifications": [
      { "desc": "Bachelor degree required or equivalent experience" },
      { "desc": "5 years of progressively responsible experience in related roles" },
      { "desc": "Proficiency in MS Office, development tools, databases, or applications as appropriate" },
      { "desc": "Strong analytical, research, systemic thinking, and problem-solving skills" },
      { "desc": "Strong organizational skills and able to effectively manage simultaneous priorities" },
      { "desc": "Effective relationship-building and interpersonal skills" },
      { "desc": "A track record of strong management/leadership" },
      { "desc": "Strong communication skills (written, verbal, presentation, and facilitation)" },
      { "desc": "Maintains high quality standards and attention to detail in work product" },
      { "desc": "Ability to perform in a self-directed manner, and to multi-task" },
      { "desc": "Aptitude to learn quickly and to rapidly apply feedback" }
    ],
    "Requirements": [
      { "da": "git/GitHub" },
      { "da": "Chef" },
      { "da": "Terraform" },
      { "da": "Docker" },
      { "da": "JIRA" },
      { "da": "Kubernetes" },
      { "da": "vmWare" },
      { "da": "Ruby" },
      { "da": "Powershell" },
      { "da": "Jenkins" },
      { "da": "Containerization and Virtualization" },
      { "da": "Infrastructure-As-Code" },
      { "da": "Unit Testing and Integration testing" },
      { "da": "Continuous Integration and Deployment" },
      { "da": "Security and Compliance" },
      { "da": "Testing" }
    ]
  },

  {
    "_id": "riso-2",
    "name": "Salesforce Product Owner",
    "about": "The Enterprise Applications (EA) Product Owner serves as a business partner across various business units and is responsible for product planning, delivery, and marketing throughout the product lifecycle for internal systems applications. This role will focus on Salesforce and similar systems at Consilio. The successful candidate will develop and maintain a roadmap for Salesforce and designated products in their portfolio based on input from internal stakeholders, customers, market research, profitability analysis, and their own industry insight. They will work closely with Development/Engineering and other teams to deliver products that align with this vision, meet market needs and are delivered to a consistently high-quality standard. The ideal candidate should possess strong leadership skills, with proven experience collaborating with project managers, business analysts, and developers to ensure successful and timely delivery that aligns with business objectives. The EA Product Owner may be expected to manage direct reports.",
    "Responsibilities": [
      { "id": "Collaborate with key stakeholders, business leaders, and IT teams to define and communicate the application strategy and roadmap, aligning it with the overall business goals." },
      { "id": "Manage the application throughout its lifecycle from concept to end-of-life." },
      { "id": "Serve as a strong business partner to relevant business units in the organization; collect and maintain business needs from key stakeholders and internal clients." },
      { "id": "Write the business case for investments and gain internal approval." },
      { "id": "Propose an internal marketing plan and budget for new system applications and then deliver against it." },
      { "id": "Work closely with project managers to plan product development initiatives, ensuring successful execution and timely delivery." },
      { "id": "Collaborate with business analysts and stakeholders to write technical business requirements and other related documents." },
      { "id": "Be the recognized expert in the business on the application, business need, and relevant technology." },
      { "id": "Develop core positioning and messaging for the application." },
      { "id": "Develop strong relationships with key internal clients and perform application demos to internal customer teams." },
      { "id": "Collaborate with project management to develop and deliver organizational change management to internal teams." },
      { "id": "Collate feedback from stakeholders and other customers. Collaborate with steering committee and stakeholders to develop roadmap." },
      { "id": "Represent the business need and development progress in senior level management meetings and reports." },
      { "id": "Be recognized as a leader in the business and build relations with business stakeholders." },
      { "id": "Manage up to three direct reports, providing mentorship, support, and guidance to nurture their professional growth." },
      { "id": "Willingness to travel, as needed." },
      { "id": "Work with development teams to provide level of effort estimates and timelines." }
    ],
    "Qualifications": [
      { "desc": "Bachelor’s degree required in Computer Science, Information Technology, or related field." },
      { "desc": "Advanced degrees and certifications in relevant technologies are a plus." },
      { "desc": "Salesforce certifications preferred." },
      { "desc": "8+ years of proven experience as a Product Owner, Product Manager, or similar role in managing multiple system applications, including Salesforce." },
      { "desc": "Strong understanding of varying modules within Salesforce and any integrated systems." },
      { "desc": "Experience collaborating within large cross-functional initiatives." },
      { "desc": "Worked in an iterative software development environment like Agile or scrum." },
      { "desc": "Excellent analytical, problem-solving, and communication skills." },
      { "desc": "Knowledge of data integration, API management, and system interoperability." },
      { "desc": "Experience with change management and user training in the context of enterprise applications." },
      { "desc": "Strong interpersonal and customer relationships skills with the ability to communicate technology solutions to non-technical teams." },
      { "desc": "A proactive approach to problem solving and the ability to anticipate client needs." },
      { "desc": "The ability to confront unexpected problems quickly and effectively." },
      { "desc": "Strong teamwork, communication (written and oral), client management, and interpersonal skills." },
      { "desc": "Experience with software development lifecycle tools like Jira." }
    ],
    "Requirements": [
      { "da": "Salesforce" },
      { "da": "Data integration" },
      { "da": "API management" },
      { "da": "System interoperability" },
      { "da": "Change management" },
      { "da": "User training" },
      { "da": "Agile or scrum methodology" },
      { "da": "Jira" },
      { "da": "Enterprise applications" }
    ]
  },



]

const jobMain1 = [
  {
    "_id": "riso-3",
    "name": "Java Software Engineer",
    "about": "We are looking for a Java Software Engineer to help us build functional products and applications. Java Software Engineer responsibilities include defining software requirements, writing clean and efficient code for various applications and running tests to improve system functionality. If you have hands-on experience with programming in Java and are interested in agile methodologies, we'd like to meet you. Ultimately, you'll implement and maintain Java components and frameworks throughout the software development life cycle.",
    "Experience": "4-6 years",
    "Job Location": ["Bangalore", "Kolkata"],
    "Exp": "4-8",
    "Education": "Any Graduate & Post Graduate",
    "Role": "Java Developer",
    "Experience with Good Java Development Experience": "Sound understanding of Java, core java, Spring-Boot (2+ years must), Oops, Log4J, Microservices, Data structures, OOPS, corejava",
    "Responsibilities": [
      { "id": "Analyze user and system requirements" },
      { "id": "Design flowcharts to illustrate software solutions" },
      { "id": "Write efficient code based on feature specifications" },
      { "id": "Develop user interfaces" },
      { "id": "Prioritize and execute tasks in the software development life cycle" },
      { "id": "Design database architecture" },
      { "id": "Test and debug Java applications" },
      { "id": "Validate software functionality and security" },
      { "id": "Analyze user and system requirements" },
      { "id": "Design flowcharts to illustrate software solutions" },
      { "id": "Write efficient code based on feature specifications" },
      { "id": "Develop user interfaces" },
      { "id": "Prioritize and execute tasks in the software development life cycle" },
      { "id": "Design database architecture" },
      { "id": "Test and debug Java applications" },
      { "id": "Validate software functionality and security" }
    ],
    "Qualifications": [
      { "desc": "Work experience as a Java Software Engineer, Java Developer or similar role" },
      { "desc": "Experience building Java EE applications" },
      { "desc": "Familiarity with Object-Oriented Design (OOD)" },
      { "desc": "Good knowledge of popular Java frameworks like JSF and Spring MVC" },
      { "desc": "Experience with test-driven development" },
      { "desc": "Problem-solving skills" },
      { "desc": "BSc in Computer Science, Engineering or relevant field" },
      { "desc": "Work experience as a Java Software Engineer, Java Developer or similar role" },
      { "desc": "Experience building Java EE applications" },
      { "desc": "Familiarity with Object-Oriented Design (OOD)" },
      { "desc": "Good knowledge of popular Java frameworks like JSF and Spring MVC" },
      { "desc": "Experience with test-driven development" },
      { "desc": "Problem-solving skills" },
      { "desc": "BSc in Computer Science, Engineering or relevant field" }
    ],
    "Requirements": [
      { "da": "Spring Boot" },
      { "da": "Microservices" },
      { "da": "Object-Oriented Programming (OOP)" },
      { "da": "Data Structures" },
      { "da": "Multithreading" },
      { "da": "Java back end developer" }
    ]
  },

  {
    "_id": "riso-5",
    "name": "MERN Stack Developer",
    "about": "We are looking for a passionate MERN Stack Developer to join our team and contribute to building robust and scalable web applications. The ideal candidate should have a strong grasp of JavaScript and its frameworks, along with experience in creating full-stack applications using MongoDB, Express.js, React.js, and Node.js.",
    "Experience": "2-5 years",
    "Job Location": ["Bangalore", "Hyderabad"],
    "Education": "Any Graduate or Post Graduate",
    "Role": "MERN Stack Developer",
    "Qualifications": [
      { "desc": "Proven experience as a MERN Stack Developer or similar role" },
      { "desc": "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model" },
      { "desc": "Experience with front-end technologies like HTML, CSS, and JavaScript frameworks, particularly React.js" },
      { "desc": "Experience with back-end technologies like Node.js and Express.js" },
      { "desc": "Familiarity with MongoDB and other NoSQL databases" },
      { "desc": "Understanding of REST APIs, GraphQL, and microservices architecture" },
      { "desc": "Knowledge of modern front-end build pipelines and tools, such as Webpack, Babel, NPM, etc." },
      { "desc": "Experience with version control systems, preferably Git" },
      { "desc": "Excellent problem-solving skills and a knack for writing clean, maintainable code" },
      { "desc": "BSc in Computer Science, Engineering or relevant field" }
    ],
    "Responsibilities": [
      { "id": "Develop and maintain high-quality, responsive web applications using the MERN stack" },
      { "id": "Collaborate with cross-functional teams to define, design, and ship new features" },
      { "id": "Write clean, maintainable, and efficient code" },
      { "id": "Ensure the performance, quality, and responsiveness of applications" },
      { "id": "Identify and address performance bottlenecks and bugs" },
      { "id": "Work with front-end developers to integrate user-facing elements with server-side logic" },
      { "id": "Participate in code reviews and provide constructive feedback" },
      { "id": "Stay up-to-date with emerging technologies and industry trends" }
    ],
    "Requirements": [
      { "da": "MongoDB" },
      { "da": "Express.js" },
      { "da": "React.js" },
      { "da": "Node.js" },
      { "da": "RESTful APIs" },
      { "da": "GraphQL" },
      { "da": "Webpack" },
      { "da": "Version control systems like Git" }
    ]
  },

]

function Carrer() {
  const [open, setOpen] = React.useState(false);
  const [openJob, setOpenJob] = React.useState(false);
  const [isBtn, setIsBtn] = useState(false);
  const [obj, setObj] = useState({});
  const [modalStyle, setModalStyle] = React.useState(getResponsiveStyle1());
  const [resume, setResume] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phno, setPhno] = useState("");
  const [modalStyle1, setModalStyle1] = React.useState(getResponsiveStyle2());
  const fileInputRef = React.useRef(null);

  const handleOpen = (data) => {
    setOpen(true);
    setObj(data);
  };

  const handleOpenJob = (data) => {
    setOpenJob(true);
    setOpen(false);
    setObj(data);
  };

  const handleResume = (e) => {
    setResume(e.target.files[0]);
  };

  const handleClose = () => setOpen(false);
  const handleCloseJob = () => setOpenJob(false);

  React.useEffect(() => {
    const handleResize = () => {
      setModalStyle(getResponsiveStyle1());
      setModalStyle1(getResponsiveStyle2());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhoneNumber = (phno) => {
    const re = /^\d{10}$/;
    return re.test(phno);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setPhno(numericValue);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, "");
    setName(alphabeticValue);
  };

  const handleApply = async () => {
    const form = new FormData();
    form.append("name", name);
    form.append("jobName", obj.name);
    form.append("email", email);
    form.append("phno", phno);
    form.append("resume", resume);

    if (name === "") {
      toast.error("Name is required");
      setIsBtn(false);
    } else if (email === "") {
      toast.error("Email is required");
      setIsBtn(false);
    } else if (!validateEmail(email)) {
      toast.error("Invalid email format");
      setIsBtn(false);
    } else if (phno === "") {
      toast.error("Phone number is required");
      setIsBtn(false);
    } else if (!validatePhoneNumber(phno)) {
      toast.error("Invalid phone number format");
      setIsBtn(false);
    } else if (resume === "") {
      toast.error("Please upload resume");
      setIsBtn(false);
    } else {
      setIsBtn(true);
      await axios.post("https://api.risoapp.com/api/job/app", form)
        .then(data => {
          if (data) {
            setIsBtn(false);
            setEmail("");
            setName("");
            setPhno("");
            setResume("");
            setOpenJob(false);
            toast.success("We received your job application, we will get back to you soon.");
          }
        })
        .catch(err => {
          if (err) {
            setIsBtn(false);
            toast.error("Something went wrong");
            setEmail("");
            setName("");
            setPhno("");
            setResume("");
            setOpenJob(false);
          }
        });
    }
  };

  return (
    <section className='carrer-sec'>
      <div className='carrer-hero'>
        <section className='carrer-inner'>
          <div className='carrer-content'>
            <h6>CAREERS AT RISO</h6>
            <h1 className='anim'>Be a part of<br />Something great</h1>
            <p className='anim'>Learn about life at RISO and why you'll love working with us. Our dynamic and inclusive workplace values creativity, collaboration, and growth. Join our team to work on cutting-edge technologies and innovative products that make a real impact. We provide the tools, resources, and training for you to excel. Enjoy a healthy work-life balance with flexible hours, wellness programs, and team-building activities.</p>
          </div>

          <div className='carrer-img anim'>
            <div className="container">
              <div className="laptop">
                <div className="laptop__screen"><img src={imgC} alt="Screen" /></div>
                <div className="laptop__bottom">
                  <div className="laptop__under"></div>
                </div>
                <div className="laptop__shadow"></div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className='heading-care'>
        <p>VALUES</p>
        <h2>We live by our 4 values</h2>
      </section>
      <section className='carrer-sec-bottom'>
        <div className='video-care'>
          <video controls autoPlay muted loop>
            <source src={mutedVideo} type="video/mp4" />
          </video>
        </div>
        <div className='right-care'>
          <section className='right-c-care'>
            <div className='care-card'>
              <div className='ic'>
                <img src={innov} />
              </div>
              <h6>Innovation</h6>
            </div>
            <div className='care-card1'>
              <div className='ic'>
                <img src={integrity} />
              </div>
              <h6>Integrity</h6>
            </div>
          </section>
          <section className='right-c-care1'>
            <div className='care-card'>
              <div className='ic'>
                <img src={team} />
              </div>
              <h6>Teamwork</h6>
            </div>
            <div className='care-card1'>
              <div className='ic'>
                <img src={exce} />
              </div>
              <h6>Excellence</h6>
            </div>
          </section>
        </div>
      </section>

      <section className='heading-care1'>
        <p>CULTURE</p>
        <h2>What it’s like to work at RISO</h2>
        <h6>Hear from our CEO, on what it’s like to work here and why this is the best time to join.</h6>
      </section>

      <section className='ceo'>
        <div className='ceo-video'>
          <video controls autoPlay muted loop>
            <source src={mutedVideo} type="video/mp4" />
          </video>
        </div>

        <div className='ceo-text-overlay'>
          <h2>Welcome to RISO!</h2>
          <p>Here, innovation meets collaboration. We value every team member's input and foster a culture of growth and excellence. With cutting-edge projects and a supportive environment, now is the best time to join us. Let's shape the future together at RISO!</p>
        </div>
      </section>
      <section className='heading-care1'>
        <p>JOB ROLE</p>
        <h2>Recent openings by role</h2>
      </section>

      <section className='role-care'>
        <div className='left-outer'>
          <div className='left-role'>
            {joMainObj?.map(job => (
              <div className='left-inner' key={job?.id}>
                <div className='left-inc-tx'>
                  <p>Position</p>
                  <h6>02</h6>
                </div>
                <div className='bld-text'>{job?.name}</div>
                <div className='det' onClick={() => handleOpen(job)}>See details <span className='detspa'><IoIosArrowForward /></span></div>
              </div>
            ))}
          </div>
          <div className='left-role'>
            {jobMain1?.map(job => (
              <div className='left-inner' key={job?.id}>
                <div className='left-inc-tx'>
                  <p>Position</p>
                  <h6>100</h6>
                </div>
                <div className='bld-text'>{job?.name}</div>
                <div className='det' onClick={() => handleOpen(job)}>See details <span className='detspa'><IoIosArrowForward /></span></div>
              </div>
            ))}
          </div>
        </div>

        <div className='right-role'>
          <div className='inn'>
            {Jd?.map(jd => (
              <div className='right-in' key={jd?.id}>
                <h6>{jd?.name}</h6>
                <p onClick={() => handleOpen(jd)}>Apply</p>
                <span>position : 0</span>
              </div>
            ))}
          </div>
        </div>
      </section>
      
      {/* upper modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ margin: "10px" }}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="book-slot-now">
              <h3>JD for ({obj?.name})</h3>
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="jd-container">
              <h4>About job role:</h4>
              <p>{obj?.about}</p>
              <h4>Responsibilities:</h4>
              <ul>
                {obj?.Responsibilities?.map(jo => (
                  <li key={jo?.id}>{jo?.id}</li>
                ))}
              </ul>
              <h4>Qualifications</h4>
              <ul>
                {obj?.Qualifications?.map(qf => (
                  <li key={qf?.desc}>{qf?.desc}</li>
                ))}
              </ul>
              <h4>Requirements</h4>
              <ul>
                {obj?.Requirements?.map(req => (
                  <li key={req?.da}>{req?.da}</li>
                ))}
              </ul>
              <button onClick={() => handleOpenJob(obj)}>Apply Now</button>
            </div>
          </Typography>
        </Box>
      </Modal>

      {/* open apply */}
      <Modal
        open={openJob}
        onClose={handleCloseJob}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ margin: "10px" }}
      >
        <Box sx={modalStyle1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="book-slot-now">
              <h3>Apply for {obj?.name}</h3>
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className='app-container'>
              <input type='text' value={name} onChange={handleNameChange} placeholder='Enter your name' />
              <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email address' />
              <input type='text' value={phno} onChange={handlePhoneNumberChange} placeholder='Enter your phone number' />
              <div className="upload-container">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleResume}
                  style={{ display: "none" }}
                />
                <p  className="upload-btn">
                <button
                  type="button"
                  onClick={() => fileInputRef.current.click()}
                 
                >
                  Upload resume
                </button>
                </p>
                {resume && <span>{resume.name}</span>}
              </div>
             {!isBtn ?  <button onClick={handleApply} >Apply Now</button> :  <button onClick={handleApply}disabled>Please wait...</button>}
            </div>
          </Typography>
        </Box>
      </Modal>
      <ToastContainer />
    </section>
  );
}

export default Carrer;

