import React from "react";
import "./Functions.css";
import { functoinCardObjectList } from "../../../data_objects/functionsObject";
import { RiAdminLine } from "react-icons/ri";

const Functions = () => {
  return (
    <div className="riso_functions section_padding" id="services">
      <div className="riso_functions_heading">
        <h2 className="riso_functions_heading_text">Our Services</h2>
        <p>
          we can get you all the support you need regarding your job search, we
          have the awesome features like:
        </p>
      </div>
      <div className="riso_functions_card_container">
        {functoinCardObjectList?.map((object, index) => (
          <div key={index} className="riso_functions_card">
            <div className="riso_functions_card_front">
              <div className="riso_function_img_container">
                 <img src={object?.icon} />
              </div>
              <h4>{object.heading}</h4>
              <p className="front_side_description">{object?.para}</p>
              <button>Learn more</button>
            </div>
            <div className="riso_functions_card_back">
              <div className="riso_function_img_container">
                 <img src={object?.icon} />
              </div>
              <h4>{object?.heading}</h4>
              <p>{object?.para}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Functions;
