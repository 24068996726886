import { ClientImage } from "../assets";

const reviewerObjectList = [
  {
    image: ClientImage,
    name: " Priya Sharma,",
    designation: "Software Engineer",
    review:
      "Riso's career counseling services were a game-changer for me. Their expert advice helped me refine my jobsearch strategy and ultimately secure a position at a top company."
  },
  {
    image: ClientImage,
    name: "Rohan Mehta,",
    designation: "College Student",
    review:
      "The educational counseling I received from Riso was exceptional. They provided me with the guidance I needed to choose the right university and program, which has set me on a path to success.",
  },
  {
    image: ClientImage,
    name: "Aisha Singh",
    designation: "Sales Representative",
    review:
      "I was struggling with social anxiety, but Riso's social counseling services helped me build confidence and improve my interpersonal skills. I feel much more comfortable in social settings now.",
  },
  {
    image: ClientImage,
    name: "Vikram Patel",
    designation: "Junior Architect",
    review:
      "The mentorship counseling at Riso was incredibly valuable. My mentor provided insightful advice and support, helping me navigate my career development effectively.",
  },
];

export default reviewerObjectList;
