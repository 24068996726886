import React,{useRef}from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import  Footer from "./components/footer/Footer"
import Navbar from "./components/navbar/Navbar";

const App = () => {
 
  return (
  
    <div>
      <Navbar/>
       <Outlet/>
      <Footer />
    </div>
  );
};

export default App;
